.modal {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
  }
  
  .close {
    float: right;
    cursor: pointer;
  }

  @keyframes bounce {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.9);
    }
  }

  .my-bounce-animation {
    animation: bounce 1.2s ease-in-out infinite;
  }

  .carbon-cal{
    /* background: #3fab36; */
    color: white;
    width: fit-content;
    /* padding: 1rem; */
    font-weight: 700;
    transition: all ease-in 0.15s;
  }

  .my-button{
    width: 10vw;
  }

  .my-bounce-animation:hover{
    animation-play-state: paused;
  }

  .panel-container .title-div{
    margin-top: 0;
  }

  @media (max-width: 1200px){
    .panel-container{
      display: none;
    }

    .my-button {
      width: 30vw;
      margin-top: 3rem;
  }
  }

