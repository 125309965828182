.aboutUs-container .title-logos {
    position: relative;
    height: auto; /* or a specific height if necessary */
}

.aboutUs-container .myImage {
    width: 100%;
    position: relative; /* or absolute, if needed */
    z-index: 1; /* lower z-index */
}

.aboutUs-container .myImage.reth {
    position: absolute;
    z-index: 2; /* higher z-index */
    bottom: 0;  /* adjust as needed */
    right: 0; /* adjust as needed */
    width: 42%; /* or as required */
}


.mision-container , .vision-container{
    height: 33vh;
    display: flex;
    padding: 1% 12%;
    align-items: center;
}

.mision-container{
    border-top-right-radius: 33vh;
}

.vision-container{
    border-bottom-right-radius: 33vh;
}

.mision-container h2, .vision-container h2{
    font-size: 2.7rem;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    transform: rotate(-90deg);
}

.mision-container p, .vision-container p{
    font-size: 1.7rem;
}

.image-container{
    border-top-right-radius: 25vh;
    border-bottom-right-radius: 25vh; 
}

.image-container img{
    border-top-right-radius: 25vh;
    border-bottom-right-radius: 25vh;
}

.image-container .address-div{
    position: absolute;
    bottom: 0;
    border-radius: 0;
    width: 100%;
    left: 0;
    color: white;
    border-bottom-right-radius: 25vh;
    overflow: hidden;
    z-index: 0;
}

.image-container h1{
    position: absolute;
    top: 25%;
    left: 30%;
    /* transform: translateX(-4rem); */
    /* transform: translateY(-4rem); */
    text-transform: uppercase;
    font-size: 8rem;
    color: white;
}

.map-container iframe{
    height: 100%;
    width: 100%;
}

.info-container{
    width: 100%;
}

.info-container .title-div{
    background: #fff;
    margin-top: 1rem;
}

.info-container .title-div h1{
    color: #e40f7f;
    font-size: 3.5rem;
    letter-spacing: 0.3rem;
}

.info-container .title-div h2{
    color: #6090b0;
    font-size: 2rem;
    font-weight: 600;
}


.info-divs {
    display: grid; /* Use Grid */
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr)); /* Adjust the number of columns and width as needed */
    gap: 5%; /* Your gap */
    padding: 5%; /* Your padding */
}


.info-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    gap: 1rem;
    padding-bottom: 25%;
    border-bottom-left-radius: 30vh;
    border-bottom-right-radius: 30vh;
    flex: 1;
}

.info-div h3{
    text-align: center;
    margin-top: 3%;
    margin-bottom: 3%;
    font-size: 1.5rem;
    font-weight: 700;
}

.info-div .info-icon-div{
    padding: 10%;
    width: 70%;
    height: fit-content;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.info-div .info-icon-div img{
    height: 15vh;
    width: 80%;
}

.info-div p{
    text-align: center;
    font-size: 1.2rem;
    padding-left: 5%;
    padding-right: 5%;
}

.mobile-h1{
    display: none;
}

@media (max-width: 1200px) {
    .location-div{
        margin-top: 4rem;
        gap: 2rem;
    }

    .aboutUs-container .title-logos{
        display: none;
    }

    .mobile-h1{
        display: block;
    }

    .desktop-h1{
        display: none;
    }

    .image-container{
        border-top-right-radius: 25vh;
        border-bottom-right-radius: 25vh; 
    }
    
    .image-container img{
        border-top-right-radius: 25vh;
        border-bottom-right-radius: 25vh;
    }
    
    .image-container .address-div{
        position: absolute;
        bottom: 0;
        border-radius: 0;
        width: 100%;
        left: 0;
        color: white;
        border-bottom-right-radius: 25vh;
        overflow: hidden;
        z-index: 0;
    }
    
    .image-container h1{
        position: absolute;
        top: 25%;
        left: 30%;
        /* transform: translateX(-4rem); */
        /* transform: translateY(-4rem); */
        text-transform: uppercase;
        font-size: 8rem;
        color: white;
    }
    
    .map-container iframe{
        height: 100%;
        width: 100%;
    }


    .vision-mision-container{
        padding: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .mision-container , .vision-container{
        height: fit-content;
        /* display: flex; */
        flex-direction: column;
        justify-self: center;
        align-items: center; 
        padding: 0 1rem;
        width: 80%;
    }
    
    .mision-container{
       border-radius: 1rem;
    }
    
    .vision-container{
       border-radius: 1rem;
    }
    
    .mision-container h2, .vision-container h2{
        font-size: 1.5rem;
        position: unset;
        width: fit-content;
        transform: rotate(0);
        padding: 0.5rem 1rem;
        margin: 1rem;
        margin-bottom: 0;
    }
    
    .mision-container p, .vision-container p{
        font-size: 1.1rem;
        text-align: center;
        margin: 1rem 0;
    }

    .info-container .info-divs {
        display: flex; /* Continue using Grid */
        flex-direction: column;
        gap: 2rem; /* Keep your gap */
        padding: 5%; /* Keep your padding */
        justify-content: center;
        align-items: center;
    }

    .info-container .info-divs .info-icon-div{
        display: none;
    }

    .info-container .info-divs .info-div{
        height:auto;
        padding: 1rem;
        width: 70%;
        gap: 0;
        border-radius: 1rem;
    }

    .info-container .info-divs .info-div h3{
        font-size: 1.3rem;
        height: fit-content;
        margin: 0;
    }

    .info-container .info-divs .info-div p{
        font-size: 1rem;
        margin: 0;
    }

    .info-container .title-div{
        padding: 1rem;
    }

    .info-container .title-div h1{
        font-size:1.5rem;
        width: 100%;
        padding: 0;
        margin: 0;
        letter-spacing: 0;
    }

    .info-container .title-div h2{
        font-size:1.2rem;
        padding: 0;
        margin: 0;
    }

}

@media (max-width: 600px) {
    .location-div{
        flex-direction: column;
        gap: 0.5rem;
    }

    .image-container{
        border-top-right-radius: 25vh;
        border-bottom-right-radius: 25vh; 
    }
    
    .image-container img{
        border-top-right-radius: 25vh;
        border-bottom-right-radius: 25vh;
    }
    
    .image-container .address-div{
        position: absolute;
        bottom: 0;
        border-radius: 0;
        width: 100%;
        left: 0;
        color: white;
        border-bottom-right-radius: 25vh;
        overflow: hidden;
        z-index: 0;
    }
    
    .image-container h1{
        text-transform: uppercase;
        font-size: 8rem;
        color: white;
    }

    .map-container{
        margin: 0 auto;
    }
    
    .map-container iframe{
        height: 100%;
        width: 100%;
    }


    .vision-mision-container{
        padding: 2rem;
    }

    .mision-container , .vision-container{
        height: fit-content;
        flex-direction: column;
        justify-self: center;
        align-items: center; 
        padding: 0 1rem;
    }
    
    .mision-container{
       border-radius: 1rem;
    }
    
    .vision-container{
       border-radius: 1rem;
    }
    
    .mision-container h2, .vision-container h2{
        font-size: 1.5rem;
        position: unset;
        width: fit-content;
        transform: rotate(0);
        padding: 0.5rem 1rem;
        margin: 1rem;
        margin-bottom: 0;
    }
    
    .mision-container p, .vision-container p{
        font-size: 1.1rem;
        text-align: center;
        margin: 1rem 0;
    }

    .info-container .info-divs {
        display: grid; /* Continue using Grid */
        grid-template-columns: 1fr; /* Set to one column that takes full width */
        gap: 2%; /* Keep your gap */
        justify-content: center;
    }

    .info-container .info-divs .info-icon-div{
        display: none;
    }

    .info-container .info-divs .info-div{
        height: fit-content;
        gap: 0;
        width: 100%;
        border-radius: 1rem;
    }

    .info-container .info-divs .info-div h3{
        font-size: 1.3rem;
        height: fit-content;
        margin: 0;
    }

    .info-container .info-divs .info-div p{
        font-size: 1rem;
        margin: 0;
    }

    .info-container .title-div{
        padding: 1rem;
    }

    .info-container .title-div h1{
        font-size:1.5rem;
        width: 100%;
        padding: 0;
        margin: 0;
        letter-spacing: 0;
    }

    .info-container .title-div h2{
        font-size:1.2rem;
        padding: 0;
        margin: 0;
    }

}