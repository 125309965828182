.logo-container img{
    height: 100%;
    width: 100%;
}


.logo-container{
    height: 150%;
    flex: 1;
    left:-9%
}

.nav-list{
    flex:4;
}

.nav-list-a, .language-div a{
    font-weight: 700;
    font-size: clamp(1rem, 1.1vw, 2rem);
    color: #231f41;
    transition: all ease-in 250ms;
}

.nav-list-a:hover{
    color: #e81981;
}

.active {
    color: #e81981;
}

.navbar{
    z-index: 9999;
}

.language-div{
    flex:1
}

div.nav-list-a:hover{
    cursor: pointer;
}

.navbar .services-div{
    background-color: #1e1c3d;
    color: white;
    font-weight: 500;
    width: 25rem;
    padding: 0.8rem 1rem;
    border-radius: 1rem;
}

.navbar .services-div li{
    padding: 0.3rem;

    transition: all ease-in 250ms;
    text-transform: uppercase;
}

.navbar .services-div li.divider{
    border-bottom: solid white 1px;
    padding: 0;
}

.navbar .services-div li:hover{
    color: #e81981;
}

.mobileNavbar{
    display: none;
}

@media (max-width: 1024px){
    .language-div{
        flex:1

    }

    .navbar{
        position: fixed;
        top: 0;
    }
    .mobileNavbar{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .navbar .mobileLogoNavbar{
        width: 10rem;
        padding: 3rem;
        padding-top: 2rem;
    }
    
    .mobileNavbar .inside-mobile-container, .nav-list-a{
        padding: 0.25rem 0.5rem;
        color:#fff;
    }

    .mobileNavbar .nav-list-a.active {
        color: #e81981;
    }
    
    .mobileNavbar .services-div li{
        font-size: 1rem;
    }
    
    .mobileNavbar .services-div{
        top: 0rem;
        width: 80%;
        right: -7.5rem;
    }
}

