.contactus-overlay{
  position: absolute;
    top:0;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.7) 100%);
    color: #fff;
    padding: 20px;
    border-radius: 1.5rem;
    /* box-sizing: border-box; */
}

.contact_locations{
  height: 50vh;
}

.loc_overlay {
  /* Other styling here */
  opacity: 1; /* Initial opacity */
  transition: all 0.4s ease; /* Smooth transition for opacity */
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.uk{
  background-image: url('/public/images/home-page-uk.jpg');
  background-size: cover;  /* This ensures the image covers the entire element */
  background-repeat: no-repeat;
}

.mx{
  background-image: url('/public/images/home-page-mx.jpg');
  background-size: cover;  /* This ensures the image covers the entire element */
  background-repeat: no-repeat;
}

.loc_overlay h1{
  opacity: 1;
  transition: all 0.2s ease;
}

/* Hover state */
.contact_locations > div:hover .loc_overlay {
  height : 0; /* Fully transparent on hover */
}

.contact_locations > div:hover .loc_overlay h1{
  opacity: 0; /* Fully transparent on hover */
}


.contactus-overlay h2{
  font-size: 2.5rem;
  font-weight: 700;
}

/* Default styles */

.contactUs_container .title-div {
  margin-top: 0;
}

.send-email-info-container {
  flex: 3;
}

.send-email-info-container h3 {
  text-align: center;
  color: #6190b1;
  font-size: 2.7rem;
  font-weight: 700;
  letter-spacing: 0.3rem;
}

.send-email-info-container p {
  font-size: 1.4rem;
  margin-top: 1rem;
}

.send-email-info-container p span {
  color: #e40f7f;
  font-weight: 700;
}

.form-container {
  flex: 4;
  height: 40vh;
}

/* Input and textarea styles */
.form-container input,
.form-container textarea {
  background-color: #edf7f9;
  padding: 0.5rem 0.8rem;
  outline: none;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  width: 100%;
  font-weight: 600;
}

textarea {
  resize: none;
  flex: 1;
}

.form-container input::-webkit-input-placeholder,
.form-container input:-ms-input-placeholder,
.form-container input:-moz-placeholder,
.form-container input::-moz-placeholder,
.form-container textarea::-webkit-input-placeholder,
.form-container textarea:-ms-input-placeholder,
.form-container textarea:-moz-placeholder,
.form-container textarea::-moz-placeholder {
  font-style: italic;
}

.form-container .form-button {
  padding: 1rem;
  border-radius: 0.8rem;
  color: white;
  font-weight: 700;
  font-size: 1.1rem;
  transition: all ease-in 150ms;
}

.form-container .form-button:hover {
  transform: scale(1.1);
}

.form-container .form-button:active {
  transform: scale(1);
  opacity: 0.8;
}

.news-letter-container h3 {
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;
}

.news-letter-container form {
  padding: 0.5rem 0.8rem;
}

.news-letter-container input {
  flex: 16;
  color: #6190b1;
  font-size: 1.2rem;
  font-weight: 600;
}

.news-letter-container button {
  flex: 1;
  color: #6190b1;
  display: flex;
  justify-content: center;
  transition: all ease-in 150ms;
}

.news-letter-container input::-webkit-input-placeholder,
.news-letter-container input:-ms-input-placeholder,
.news-letter-container input:-moz-placeholder,
.news-letter-container input::-moz-placeholder {
  color: #6190b1;
}

.news-letter-container input:focus {
  outline: none;
}

.news-letter-container button:active {
  transform: scale(0.8);
}

.news-letter-container button:hover {
  transform: scale(1.2);
}

/* Media query for screens with max-width 850px */

@media (max-width: 1024px) {
  .title-container img {
      display: none;
  }

  .title-container {
      padding: 6rem 1rem;
  }

  .contactUs_container .title-div {
      margin-top: 2rem;
      top: 5rem;
      margin: auto;
  }

  .send-email-container {
      flex-direction: column;
      padding: 1rem;
  }

  .send-email-info-container {
      flex: 3;
  }

  .send-email-info-container h3 {
      text-align: center;
      color: #6190b1;
      font-size: 1.7rem;
      font-weight: 700;
      letter-spacing: 0.1rem;
  }

  .send-email-info-container p {
      font-size: 1.1rem;
      margin-top: 0.5rem;
  }

  .send-email-info-container p span {
      color: #e40f7f;
      font-weight: 700;
  }

  .form-container {
      flex: 4;
      height: auto; /* Adjust height as needed for smaller screens */
  }

  .news-letter-container {
      padding: 1rem;
      margin: 1rem;
  }

  .news-letter-container h3 {
      font-size: 1.3rem;
  }

  .news-letter-container form {
      padding: 0.5rem 0.8rem;
      width: 100%;
  }

  .contact_locations{
    display: none;
  }
}

