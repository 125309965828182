/****************************
        Home Page CSS
*****************************/

/****************************
        Hero Section
*****************************/
.hero-gear{
    width: 4%;
    height: 4%;
    position: absolute;
  }
  
  .purple-gear{
    bottom: 10%;
    left: 25% ;
  }
  
  .blue-gear{
    top: 45%;
    left: -45%;
  }
  
  .hero-container{
    height: 100vh;
    display: grid;
    /* flex-direction: column; */
    padding-right: 6%;
    padding-left: 6%;
  }
  
  .hero-text{
    align-self:center;
    width: 75%;
    justify-self: center;
  }
  
  .hero-text h2
  {
    font-size: clamp(1.4rem, 1.8vw, 1.9rem);
    text-align: center;
    color: #6190b1;
    font-weight: 600;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
  }
  
  .hero-text h2 span{
    color: #e40f7f;
    font-weight: 800;
  }
  
  .hero-logo-container{
    width: 60%;
    display: flex;
    justify-self: start;
    flex-direction: column;
  }
  
  .hero-logo-container img{
    margin-bottom: 16%;
    width: 70%;
    margin-left: 3%;
  }
  
  .hero-logo-container p{
    color: #e40f7f;
    text-transform: uppercase;
    font-size: 2.2rem;
    font-weight: 700;
    letter-spacing: 0.1rem;
  }
  
  
  .hero-style-container{
    top: 0;
    right: 0;
    height: 100%;
  }
  
  /****************************
          Partners
  *****************************/
  
  /* Add this CSS to the stylesheet that affects your carousel components */
  .partner img {
    -webkit-user-drag: none; /* This will prevent dragging of images on webkit browsers like Chrome and Safari */
    user-select: none; /* This will prevent text selection */
    width: 90%;
    height: 90%;
    object-fit: contain;
  }
  
  /* For other browsers */
  .partner {
    -moz-user-select: none; /* This will prevent text selection on Firefox */
    -ms-user-select: none; /* This will prevent text selection on Internet Explorer/Edge */
    /* margin: 3%; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-wrapper{
    align-items: center;
  }
  
  /****************************
          Our Services
  *****************************/
  .services{
    padding: 3%;
  }
  
  .services-container h1{
    font-size: 4.5rem;
    color: #e40f7f;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.7rem;
  }
  
  .service{
    background-color: #1d1636;
    color: white;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 1%;
    height: 30vh;
    position: relative;
    transition: all 200ms ease-in;
  }
  
  .service:hover{
    /* transform: translateY(-1.2rem); */
    translate: 0 -1rem;
  }
  
  .service .image-container{
    width: 100%;
    height: 50%;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .service .image-container img {
    height: 160%;
  }
  
  .service h2{
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0;
    text-transform: uppercase;
  }
  
  .service .view-more-container {
    position: absolute;
    bottom: -0.6rem;
  
    padding: 0.2rem 0.7rem;
    border-radius: 0.6rem;
    text-align: center;
  
    align-self: center;
    transition: all 200ms ease-in;
  }
  
  .service .view-more-container a{
    font-size: 1.2rem;
    font-weight: 500;
  }
  
  .service .view-more-container:hover{
    scale: 1.15;
  }
  
  /****************************
          Testimonials
  *****************************/
  .testimonial{
      border: #6090b0 6px solid;
      border-radius: 3rem;
      padding: 2rem 6rem;
      transition: all ease-in 300ms;
      position: relative;
      z-index: 9999;
  }
  
  .testimonial-logo-container{
      flex: 1;
      height: 100%;
      align-self:flex-start;
  }
  
  .testimonial-text{
      flex: 2;
      text-align: left;
  }
  
  .upper-quote , .lower-quote{
      color: #6090b0;
      font-size: 5rem;
      width: 7rem;
      height: 7rem;
      position: absolute;
      z-index: 9999;
      padding: 0.5rem;
      background-color: white;
  }
  
  .upper-quote{
      top: 0.3rem;
      left: 4rem;
  }
  
  .lower-quote{
      bottom: 0.3rem;
      right: 4rem;
  }
  
  .testimonial-previous-btn{
      position: absolute;
      top:10rem;
      left:3rem;
      font-size: 4rem;
      font-weight: 100;
      color: #6090b0;
  }
  
  .testimonial-next-btn{
      position: absolute;
      top:10rem;
      right:3rem;
      font-size: 4rem;
      font-weight: 100;
      color: #6090b0;
  }
  
  .testimonials h1, .partners h1
  {
      color: #9c9ba8;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 1rem;
      font-size: 2rem;
  }
  
  
  /****************************
      Location UK MX Container
  *****************************/
  
  .HomePage .location-container{
      position: relative;
      height: 51vh;
      width: 100%;
      display: flex;
      /* background-color: aqua;  */
    }
    
    .location-container img{
      height: 100%;
    }
    
    .location-container .image-container-uk{
      height: 100%;
      position: absolute;
      width: 55%;
      right: 0;
      padding: 21px;
      padding-left: 0;
      padding-right: 0;
    }
    
    .image-container-uk img{
      width: 100%;
    }
    
    .location-container .image-container-mx{
      height: 100%;
      position: absolute;
      left: 0;
      padding: 21px;
      padding-left: 0;
      background-color: white;
      width: 55%;
      /* transform: scale(1.11); */
      border-top-right-radius: 300px;
      border-bottom-right-radius: 300px; 
    }
    
    .image-container-mx img{
      width: 100%;
      border-top-right-radius: 284px;
      border-bottom-right-radius: 284px; 
    }
    
    .mx-heading, .uk-heading{
      font-size: 9rem;
      color: white;
      position: absolute;
    }
    
    .mx-heading{
      top: 1rem;
      right: 9rem;
    }
    
    .uk-heading{
      top: 1rem;
      left: 12rem;
    }
    
    .location-marker{
      display: flex;
      color: white;
      position: absolute;
      align-items: center;  
    }
    
    .location-marker .icon-container{
      border-radius: 50%;
      padding: .69rem;
      height: 3.6rem;
      z-index: 1;
      width: 3.6rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .icon-container img, .image-container-mx .icon-container img{
      height: 100%;
      border-radius: 0;
    }
    
    .location-marker .city-name{
      padding: 0 1rem;
      height: 1.8rem;
      text-align: center;
      font-weight: 500;
      font-size: 1.2rem;
      border-top-right-radius: 1rem;
      border-bottom-right-radius: 1rem;  
      position: absolute;
      left:3rem;
    }
    
    .image-container-uk .location-marker{
      bottom: 2.5rem;
      left: 10rem;
    }
    
    .image-container-uk .location-marker .icon-container{
      background-color: #6090b0;
    }
    
    .image-container-uk .location-marker .city-name{
      background-color: #1aafdc;
    }
    
    .image-container-mx .location-marker{
      bottom: 2.5rem;
      left: 10rem;
    }
    
    .image-container-mx .location-marker .icon-container{
      background-color: #e40f7f;
    }
    
    .image-container-mx .location-marker .city-name{
      background-color: #ec609a;  
      width:max-content;
    }
  
    @media (max-width: 1200px) {
      .hero-container{
        padding-top: 15vh !important;
        height: 80vh;
      }

      .HomePage .location-container{
        display: none;
      }
    
      .mx-heading, .uk-heading{
        display: none;
      }
    
      .HomePage .location-container{
        height: 20vh;
        gap: 0.5rem;
      }
    
      .location-container .image-container-mx,.location-container .image-container-uk{
        position: relative;
        margin: 0;
        padding: 8px;
      }
    
      .image-container-mx img, .image-container-uk img{
        border-radius: 85px;
      }
    
      .image-container-uk .location-marker{
        bottom: 3rem;
        left: 0;
      }
    
      .image-container-mx .location-marker{
        bottom: 3rem;
        left: -1rem;
      }
    
      .location-marker .city-name{
        border-radius: 1rem;
        font-size: 1rem;
        height: 1.5rem;
      }
    
      .location-marker .icon-container{
        display: none;
      }
    
      .testimonial{
        /* height: fit-content; */
        padding: 2.5rem;
        gap: 1rem;
        border: #6090b0 4px solid;
        overflow: hidden;
        width: 100%;
      }
    
      .testimonials h1, .partners h1
      {
        font-size: 1.3rem;
        letter-spacing: 0.5rem;
      }
    
      .upper-quote , .lower-quote{
        width: 4rem;
        height: 4rem;
        padding: 0rem;
      }
    
      .testimonial-logo-container{
        flex: 1;
        height: 70%;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 5vh;
    }
    
    .testimonial-logo-container img{
      height: 100%;
      margin: auto;
    }
      
    .testimonial-text{
      font-size: 0.8rem;
      text-align: center;
    }
    
    .testimonial-previous-btn{
      font-size: 3rem;
      left: 1rem;
      top: 50%;
      font-weight: 600;
    }
    
    .testimonial-next-btn{
      font-size: 3rem;
      right:1rem;
      top:50%;
      font-weight: 600;
    }
    
    .upper-quote{
      left: 3rem;
      top: 1rem;
    }
    
    .lower-quote{
      bottom: 1rem;
      right: 3rem;
    }
    
    .service .image-container{
    flex:1;
    justify-self: flex-end;
    }
    
    .service h2{
    flex:2
    }
    
    .services-container h1{
    font-size: 1.8rem;
    letter-spacing: 0.2rem;
    }
    
    .hero-style-container{
    display: none;
    }
    
    .hero-text{
    width: 90%;
    }
    
    .hero-text h2{
    font-size: 1rem;
    }
    
    .hero-container{
    padding: 1rem;
    margin-top: 0rem;
    }
    
    .HomePage{
    padding-top: 1rem;
    }
    
    .hero-logo-container{
    width: 100%;
    }
    
    .hero-logo-container img{
    width: 90%;
    }
    
    .hero-logo-container p{
    font-size: 1.5rem;
    text-align: center;
    }
    
    .hero-gear{
    display: none;
    }
    
    .partners{
    margin-top: 0;
    display: block;
    }
    
    .partner{
    height: 33vh;
    margin: -3rem 0;
    width: 100%;
    }
    
    .partner img {
    /* width: 100%; */
    height: 100%;
    }
    
    .services {
      padding: 1rem;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(350px, 1fr)); /* Adjust the minmax values as needed */
      gap: 20px; /* Adjust the gap between items as needed */
      /* Add any additional styling for the .services container */
    }
    
    .service {
      width: 100%;
      height: 100%;
      margin: 0;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: row;
      /* Add any additional styling for the .service items */
    }
    }
    
    @media (max-width: 600px) {
      .HomePage .location-container{
        display: none;
      }
  
      .mx-heading, .uk-heading{
        display: none;
      }
    
      .image-container-mx img, .image-container-uk img{
        border-radius: 85px;
      }
    
      .image-container-uk .location-marker{
        bottom: 3rem;
        left: 0;
      }
    
      .image-container-mx .location-marker{
        bottom: 3rem;
        left: -1rem;
      }
    
      .location-marker .city-name{
        border-radius: 1rem;
        font-size: 1rem;
        height: 1.5rem;
      }
    
      .location-marker .icon-container{
        display: none;
      }
  
      .testimonial{
        height: fit-content;
        flex-direction: column;
        padding: 0.5rem;
        gap: 1rem;
        border: #6090b0 4px solid;
        overflow: hidden;
        width: 100%;
      }
  
      .testimonials h1, .partners h1
      {
        font-size: 1.3rem;
        letter-spacing: 0.5rem;
      }
  
      .upper-quote , .lower-quote{
        width: 4rem;
        height: 4rem;
        padding: 0rem;
      }
  
      .testimonial-logo-container{
        flex: 0;
        height: 30%;
    }
  
    .testimonial-logo-container img{
      height: 100%;
    }
      
    .testimonial-text{
      font-size: 1rem;
      padding-bottom: 5vh;
    }
  
    .testimonial-previous-btn{
      font-size: 3rem;
      left: 1rem;
      top: 50%;
      font-weight: 600;
    }
  
    .testimonial-next-btn{
      font-size: 3rem;
      right:1rem;
      top:50%;
      font-weight: 600;
    }
  
    .upper-quote{
      left: 3rem;
      top: 1rem;
    }
  
    .lower-quote{
      bottom: 1rem;
      right: 3rem;
  }
  
  .services{
    flex-direction: column;
  }
  
  .service{
    width: 100%;
    /* height: 25%; */
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  
  .service .image-container{
    flex:1;
    justify-self: flex-end;
  }
  
  .service h2{
    flex:2
  }
  
  .services-container h1{
    font-size: 1.8rem;
    letter-spacing: 0.2rem;
  }
  
  .hero-style-container{
    display: none;
  }
  
  .hero-text{
    width: 90%;
  }
  
  .hero-text h2{
    font-size: 1rem;
  }
  
  .hero-container{
    padding: 1rem;
    margin-top: 0rem;
  }
  
  .hero-logo-container{
    width: 100%;
  }
  
  .hero-logo-container img{
    width: 90%;
  }
  
  .hero-logo-container p{
    font-size: 1.5rem;
    text-align: center;
  }
  
  .hero-gear{
    display: none;
  }
    
  .partners{
    margin-top: 2rem;
    display: block;
  }
  
  .partner{
    /* height: 50vh; */
    margin: -3rem 0;
    width: 100%;
  }
  
  .partner img {
    width: 100%;
  }
  }
  
  
    