@tailwind base;
@tailwind components;
@tailwind utilities;


*{
  margin: 0;
  padding: 0;
}

body{
  font-family: 'Nunito', sans-serif;
}

html,body, :root{
  height: 100%;
  color-scheme: light ;
}


.pink-bg{
  background-color: #e50f7e;
}

.z-9999{
  z-index: 99999;
}

.color-pink{
  color: #e50f7e;
}

.purple-bg{
  background-color: #642682;
}

.dark-blue-bg{
  background-color: #1e1c3d;
}

.blue-bg{
  background-color: #6190b1;
}

.green-bg{
  background-color: #7aba24;
}

.dark-green-bg{
  background-color: #3fab36;
}

.orange-bg{
  background-color: #e94e1c;
}

.yellow-bg{
  background-color: #f19208;
}

.light-blue-bg{
  background-color: #16aedc;
}

.light-pink-bg{
  background-color: #ec619b;
}



