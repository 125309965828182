.services-method-div{
    /* height: 30vh; */
    background-color: #e8e8e8;
    border-radius: 1rem;
    margin-top: 3rem;
}

.results-title-div h2, .methods-container h1, .test-title-div h2{
    color: #9c9ba8;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1.3rem;
    font-size: 2rem;
    text-align: center;
}

.test-title-div img{
  width: 80%;
}

/* ServiceResults.css */

/* Example styles to adjust the position of navigation buttons */
.mySwiper {
    position: relative;
  }
  
  .results-div .slideNextBtn,
  .results-div .slidePrevBtn {
    position: absolute;
    top: 60%; /* Adjust the top position as needed */
    transform: translateY(-50%);
  }
  
  .results-div .slideNextBtn {
    right: -3rem; /* Adjust the right position as needed */
    z-index: 999;
    color: #9c9ba8;
  }
  
  .results-div .slidePrevBtn {
    left: -3rem; /* Adjust the left position as needed */
    z-index: 999;
    color: #9c9ba8;
  }

  .services-method-div{
    padding: 1rem;
    flex-direction: column;
  }

  .services-method-div p{
    height: 10rem;
    font-size: 1.5rem;
    line-height: 1.5;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    margin-bottom: 0; /* Optional to remove default margin */
    
    font-weight: 700;
    padding: 1rem;
  }
  
  .service-method-logo{
    height: 12rem;
    width: max-content;
    padding: 1.15rem;
  }

  .service-method-logo img{
    height: 100%;
  }

  .method{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 0.5rem;
    padding-bottom: 0;
    flex: 1;
  }

  .number-div{
    position:absolute;
    top: 0;
    left: -0.5rem;
    bottom: 0;
    width: 3px;
  }

  .number-div h2{
    position: absolute;
    top: -1rem;
    left:-1.7rem;
    display: inline;
    padding: 0.2rem 0.7rem;
    font-size: 2rem;
    font-weight: 700;
    border-radius: 100%;
  }

  .number-div h2 sup{
    font-size: 0.9rem;
    vertical-align:text-top;
    line-height: 2.5rem;
  }

  .method .icon-container{
    width: 60%;
    height: 10rem;
    padding: 1rem;
    position: absolute;
    top:-120px;
  }

  .icon-container img{
    height: 100%;
  }
  
  .method-desc{
    padding: 1rem;
    height: 90%;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
  }

  .method-desc h2{
    text-transform: uppercase;
    margin-bottom: 0.8rem;
    font-weight: 700;
    font-size: 1.2rem;
  }

  .method-desc p{
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }

  .methods{
    height: 100%;
  }

  .methods-container{
    height: 46rem;
  }

  .h-85{
    height: 90%;
  }

  .test-image-div img {
    border-radius: 9999px;
  }

  .test-title-div h2{
    font-size: 1.2rem;
    text-align:left;
    margin-top: 2rem;
    margin-bottom: 0.7rem;
    letter-spacing: 0.85rem;
  }

  .service-title-container{
    padding-left: 5rem;
    padding-right: 5rem;
    padding-top: 2%;
  }

  .title-image-container{
    flex:3;
    /* height: 55rem; */
  }

  .title-image-container img{
    width: 100%;
    height: auto;
  }

 .quote-container{
    flex:4
 }

 .quote-container p{
  padding-top: 5rem;
 }

  .quote-container p, .service-title-container .quote-container .extra-quote span{
    color: #e50f7e;
    font-weight: 700;
    font-size: 1.7rem;
  }

  .quote-container .author{
    font-weight: 400;
    font-style: italic;
    padding-top: 0;
  }

  .service-title-div{
    position: absolute;
    top: -3%;
    left: 63%;
    padding: 10px;
    border-radius: 3rem;
    /* width: 120%; */
    width: max-content;
  }

  .service-title-div h1{
    color: white;
    text-align: center;
    text-transform: uppercase;
    font-size: 2.7rem;
    letter-spacing: 0.2rem;
    font-weight: 700;
    border-radius: 3rem;
    padding: 0.1rem 4rem;
  }

  .colored-desc{
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding: 0.5rem 17%;
    color: white;
    font-size: 1.3rem;
  }

  .hol{
    padding: 0.5rem 8%;
  }

  .colored-desc p{
    text-align: center;
  }

  .pink-desc-container{
    margin-right: 5rem;
    border-top-right-radius: 9rem ;
    border-bottom-right-radius: 9rem ;
  }

  .arrow-div{
    width: 40%;
    padding: 0.2rem;
    padding-left: 5rem;
    display: flex;
    align-items: center;
  }

  .arrow-div img{
    width: 80%;
  }

  .pink-desc{
    color: white;
    padding: 2rem;
    padding-left: 0;
  }

  .pink-desc h2{
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.7rem;
  }

  .pink-desc p{
    font-size: 1.3rem;
    margin: 1rem 0;
  }

  .test-image{
    opacity: 0.9;
  }

  .test-image-inner-1{
    border: solid 2rem #642682;
  }

  .test-image-inner-2{
    border: solid 2rem #6190b1;
  }

  .test-image-inner-3{
    border: solid 2rem #3fab36;
  }

  .test-image-inner-4{
    border: solid 2rem #e50f7e;
  }

  .test-image-inner-5{
    border: solid 2rem #e94e1c;
  }


/******************************
    Corporate Service
*******************************/

.corporate-service-container .title-service-div {
    color: white;
    left: 0;
    right: 0;
    top:-2.2rem;
    display: flex;
    justify-content: center;
    font-size: 3rem;
    font-weight: 700;
    padding: 0.6rem 2rem;
    border-top-right-radius: 3rem;
    border-bottom-right-radius: 3rem;
}

/* .corporate-service-container .title-container{
  padding-top: 2%;
} */

.corporate-service-container .title-service-div-image{
    position: absolute;
    top: -4rem;
    left: 4rem;
    width: 16%;
}

.corporate-service-container .service-info-div p{
    color: #e50f7e;
    font-size: 1.8rem;
    text-align: center;
    font-weight: 700;
    padding: 3rem;
}

.corporate-service-container .service-info-div-2 p{
    color: white;
    font-size: 1.4rem;
    padding: 1rem 3.7rem;
    text-align: center;
}

.rethical-image-div{
    flex:2
}

.rethical-dec-div{
    flex:3;
    padding-right: 5%;
    padding-left: 5%;
}

.rethical-dec-div p{
  width: 85%;
}

.rethical-dec-div .rethical-title{
    color: #e50f7e;
    font-size: 1.5rem;
    font-weight: 700;
}

.rethical-dec-div .rethical-li{
    font-size: 1.4rem;
    margin-top: 1.8rem;
    display: flex;
    width: 100%;
    position: relative;
}

.rethical-li-bullet{
    height: 0.7rem;
    position: absolute;
    top: 0.6rem;
    left: -1.3rem;
    width: 0.7rem;
    border-radius: 50%;
    background-color: #e50f7e; /* Set the color of the bullet */
    margin-right: 0.5em; /* Add some space between the bullet and the text */
  }

  .service-title-container .quote-container .extra-quote{
    font-weight: 400;
    color: black;
    font-size: 1.5rem;
  }

  @media (max-width: 1200px) {
    .corporate-service-container .title-container{
      padding: 0;
      padding-top: 9rem;
    }

    .corporate-service-container .title-service-div{
      flex-direction: column;
      padding-top: 0;
    }

    .corporate-service-container .title-service-div-image{
      /* position:unset; */
    }

    .corporate-service-container .bg-image-div{
      padding: 1rem;
    }

    .bg-image-div img{
      display: none;
    }

    .corporate-service-container .title-service-div{
      margin: 1rem;
      border-top-left-radius: 3rem;
      border-bottom-left-radius: 3rem;
      padding: 1rem;
      
    }

    .corporate-service-container .title-service-div h1{
      font-size: 1.35rem;
      text-align: center;
      padding: 0;
    }

    .corporate-service-container .service-info-div-2{
      margin: 0;
    }

    .corporate-service-container .service-info-div p{
      font-size: 1.3rem;
    }

    .corporate-service-container .service-info-div-2 p{
      font-size: 1.2rem;
    }

    .rethical-container{
      flex-direction: column;
      gap: 1rem;
      margin-right: 2rem;
    }

    .rethical-image-div{
      width: 60%;
    }

    .rethical-dec-div{
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 1.6rem;
    }

    .rethical-dec-div .rethical-title{
      font-size: 1.2rem;
    }

    .rethical-dec-div .rethical-li{
      font-size: 1.1rem;
    }

    .rethical-li-bullet{
      height: 0.5rem;
      width: 0.5rem;
      left: -0.65rem;
    }

    .results-title-div h2, .methods-container h1{
      font-size: 1.3rem;
      letter-spacing: 0.5rem;
    }

    .service-method-logo{
      width: 70%;
    }

    .service-method-logo img{
      width: 100%;
    }

    .services-method-div{
      padding: 0.1rem;
    }

    .services-method-div p{
      text-align: center;
      font-size: 1rem;
      padding: 0.5rem;
      font-weight: 700;
      height: 4rem;
      line-height: 1rem;
    }

    .methods{
      flex-direction: column;
      height: fit-content;
      gap: 0.5rem;
      padding: 4.5rem;
      justify-content: center;
      align-items: center;
    }

    .method{
      height: 30vh;
      width: 60%;
    }

    .number-div{
      border: none;
      background-color: white;
    }

    .number-div h2{
      left: -2.4rem;
      top: 0;
      font-size: 1.2rem;
    }

    .number-div h2 sup{
      font-size: 0.7rem;
      line-height: 1.5rem;
    }

    .method-desc h2{
      font-size: 1rem;
      margin-bottom: 0.5rem;
      text-align: center;
    }

    .method-desc p{
      font-size: 1.1rem;
      text-align: center;
    }

    .method .icon-container{
      width: 12%;
      padding: 0;
      top: -3rem;
      right: -15%;
    }

    .methods-container{
      height: auto;
    }

    .method-desc{
      padding: 0.5rem;
      height: 90%;
      border-radius: 1rem;
    }

    .test-image{
      display: none;
    }

    .test-desc-div{
      padding: 1.5rem;
    }

    .test-desc-div p {
      font-size: 0.9rem;
      font-weight: 500;
      text-align: center;
    }

    .test-desc-div .test-title-div{
      width: 100%;
    }

    .test-title-div{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .test-title-div img{
      width: 80%;
    }

    .test-desc-div .test-title-div h2{
      text-align: center;
      width: 100%;
    }

    .service-title-container{
      flex-direction: column;
    }

    .service-title-container .title-image-container{
      width: 80%; 
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .service-title-container .title-image-container img{
      display: none;
    }

    .service-title-container .title-image-container .service-title-div{
      position: unset;
    }

    .service-title-container .title-image-container .service-title-div h1{
      padding: 0;  
      font-size: 1.5rem;
      letter-spacing: 0.1rem;
      padding: 0 1rem;
      text-align: center;
    }

    .service-title-container{
      padding-top: 9rem;
      padding-left: 1rem;
      padding-right: 1rem;
      box-sizing: content-box;
    }

    .service-title-container .quote-container p, .service-title-container .quote-container .extra-quote{
      font-size: 1rem;
      margin-top: 1rem;
      text-align: center;
      margin: 1rem;
    }

    .service-desc-conatiner .colored-desc{
      padding: 1rem;
      font-size: 1rem;
    }

    .quote-container .author{
      margin: 0;
    }

    .service-desc-conatiner .pink-desc-container{
      margin: 1rem;
      padding: 0;

      flex-direction: column;
      border-radius: 1rem;
      align-items: center;
    }

    .arrow-div{
      display: none;
    }

    .service-desc-conatiner .pink-desc-container .pink-desc{
      padding: 1rem;
    }

    .service-desc-conatiner .pink-desc-container .pink-desc h2, .service-desc-conatiner .pink-desc-container .pink-desc p{
      text-align: center;
      font-size: 1.2rem;
    }

    .service-desc-conatiner .pink-desc-container .pink-desc p{
      font-size: 1rem;
    }

    .rethical-container{
      margin-top: 1rem;
    }
  }



  @media (max-width: 600px) {
    .corporate-service-container .title-container{
      padding: 0;
      padding-top: 9rem;
    }

    .aboutUs-container .title-logos{
      display: none;
    }

    .corporate-service-container .title-service-div{
      flex-direction: column;
      padding-top: 0;
    }

    .corporate-service-container .title-service-div-image{
      /* position:unset; */
    }

    .corporate-service-container .bg-image-div{
      padding: 1rem;
    }

    .bg-image-div img{
      display: none;
    }

    .corporate-service-container .title-service-div{
      margin: 1rem;
      border-top-left-radius: 3rem;
      border-bottom-left-radius: 3rem;
      padding: 1rem;
      
    }

    .corporate-service-container .title-service-div h1{
      font-size: 1.35rem;
      text-align: center;
      padding: 0;
    }

    .corporate-service-container .service-info-div-2{
      margin: 0;
    }

    .corporate-service-container .service-info-div p{
      font-size: 1.3rem;
    }

    .corporate-service-container .service-info-div-2 p{
      font-size: 1.2rem;
    }

    .rethical-container{
      flex-direction: column;
      gap: 1rem;
      align-items: center;
    }

    .rethical-dec-div{
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 1.5rem;
    }

    .rethical-dec-div .rethical-title{
      font-size: 1.2rem;
    }

    .rethical-dec-div .rethical-li{
      font-size: 1.1rem;
    }

    .rethical-dec-div p{
      width: 100%;
    }

    .rethical-li-bullet{
      height: 0.5rem;
      width: 0.5rem;
      /* left: -0.65rem; */
    }

    .results-title-div h2, .methods-container h1{
      font-size: 1.3rem;
      letter-spacing: 0.5rem;
    }

    .service-method-logo{
      width: 70%;
    }

    .service-method-logo img{
      width: 100%;
    }

    .services-method-div{
      padding: 0.1rem;
    }

    .services-method-div p{
      text-align: center;
      font-size: 1rem;
      padding: 0.5rem;
      font-weight: 700;
      height: 4rem;
      line-height: 1rem;
    }

    .methods{
      flex-direction: column;
      height: fit-content;
      gap: 0.5rem;
      padding: 4.5rem;
    }

    .method{
      height: 30vh;
      width: 100%;
    }

    .number-div{
      border: none;
      background-color: white;
    }

    .number-div h2{
      left: -2.4rem;
      top: 0;
      font-size: 1.2rem;
    }

    .number-div h2 sup{
      font-size: 0.7rem;
      line-height: 1.5rem;
    }

    .method-desc h2{
      font-size: 1rem;
      margin-bottom: 0.5rem;
      text-align: center;
    }

    .method-desc p{
      font-size: 1.1rem;
      text-align: center;
    }

    .method .icon-container{
      width: 20%;
      padding: 0;
      top: -3rem;
      right: -3.3rem;
    }

    .method-desc{
      padding: 0.5rem;
      height: 90%;
      border-radius: 1rem;
    }

    .test-image{
      display: none;
    }

    .test-desc-div{
      padding: 1.5rem;
    }

    .test-desc-div p {
      font-size: 0.9rem;
      font-weight: 500;
      text-align: center;
    }

    .test-desc-div .test-title-div{
      width: 100%;
    }

    .test-title-div{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .test-title-div img{
      width: 80%;
    }

    .test-desc-div .test-title-div h2{
      text-align: center;
      width: 100%;
    }

    .service-title-container{
      flex-direction: column;
    }

    .service-title-container .title-image-container{
      width: 80%; 
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .service-title-container .title-image-container img{
      display: none;
    }

    .service-title-container .title-image-container .service-title-div{
      position: unset;
    }

    .service-title-container .title-image-container .service-title-div h1{
      padding: 0;  
      font-size: 1.5rem;
      letter-spacing: 0.1rem;
      padding: 0 1rem;
      text-align: center;
    }

    .service-title-container{
      padding-top: 9rem;
      padding-left: 1rem;
      padding-right: 1rem;
      box-sizing: content-box;
    }

    .service-title-container .quote-container p, .service-title-container .quote-container .extra-quote{
      font-size: 1rem;
      margin-top: 1rem;
      text-align: center;
      margin: 1rem;
    }

    .service-desc-conatiner .colored-desc{
      padding: 1rem;
      font-size: 1rem;
    }

    .quote-container .author{
      margin: 0;
    }

    .service-desc-conatiner .pink-desc-container{
      margin: 1rem;
      padding: 0;

      flex-direction: column;
      border-radius: 1rem;
      align-items: center;
    }

    .arrow-div{
      display: none;
    }

    .service-desc-conatiner .pink-desc-container .pink-desc{
      padding: 1rem;
    }

    .service-desc-conatiner .pink-desc-container .pink-desc h2, .service-desc-conatiner .pink-desc-container .pink-desc p{
      text-align: center;
      font-size: 1.2rem;
    }

    .service-desc-conatiner .pink-desc-container .pink-desc p{
      font-size: 1rem;
    }

    .rethical-container{
      margin-top: 1rem;
    }
  }


  









