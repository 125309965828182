.rights-container{
    background-color: #652682;
    color: white;
    padding: 1rem;
}


/**************************************
            Contacts Container
**************************************/

.logo-container{
    flex: 1;
}

/**************************************
            Social Media Container
**************************************/

.social-media-container{
    flex: 3;
}

.social-media-text{
    color: #e50f7e;
    font-size: 1.2rem;
    font-weight: 700;
}

.social-media-links{
    border-radius: 0.8rem;
    transition: all ease-in 150ms;
    opacity: 1;
    color: white;
}

.social-media-links:hover{
    scale: 0.9;
    opacity: 0.85;
}

#whatsapp_link{
    background-color: #1dcc40;
}

#facebook_link{
    background-color: #652682;
}

#instagram_link{
    background-color: #e50f7e;
}

#linkedin_link{
    background-color: #6191b0;
}

#youtube_link{
    background-color: #e94f1d;
}

#spotify_link{
    background-color: #3eab37;
}

/**************************************
            Address Container
**************************************/

.address-container{
    flex: 3;
    background-color: #e50f7e;
    color: white;
    padding: 2.1rem 4.2rem;
    border-top-left-radius: 150px;
    border-bottom-left-radius: 150px;
    margin-bottom: 2rem;
}

.address-container h2{
    margin-bottom: 1.2rem;
}

.address-container p{
    margin: 0.5rem 0;
    font-size: 1.2rem;
    padding: 0;
    width: 100%;
    text-align: left;
}

@media (max-width: 1024px) {
    .address-container{
        border-radius: 1rem;
        text-align: center;
    }

    .address-container p
    {
        text-align: left;
        font-size: larger;
        margin: 0.5rem;
    }

    .address-container .email{
        text-align: center;
    }

    .social-media-container{
        width: 100%;
    }

    .social-media-links{
        height: 3.3rem;
    }

    .logo-container{
        display: none;
    }
    .contacts-container{
        flex-direction: column;
        padding: 0.8rem;
    }
    .rights-container{
        width: 100%;
        flex-direction: column;
        gap: 1rem;
    }
}

@media (max-width: 600px) {
    
    .address-container{
        border-radius: 1rem;
        text-align: center;
        padding: 1.2rem;
    }

    .address-container p
    {
        text-align: left;
        font-size: larger;
        margin: 0.5rem;
    }

    .address-container .email{
        text-align: center;
    }

    .social-media-container{
        width: 100%;
    }

    .social-media-links{
        height: 3.3rem;
    }

    .logo-container{
        display: none;
    }
    .contacts-container{
        flex-direction: column;
        padding: 0.8rem;
    }
    .rights-container{
        width: 100%;
        flex-direction: column;
        gap: 1rem;
    }
}
