.brainButtons:hover{
    cursor: pointer;
    scale: 1.2;
}
.button-container{
    bottom: 3%;
}
.spotify-icon svg, .youtube-icon svg {
    font-size: 55px; /* This sets the icon size */
  }

.clamp-3 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3; /* Number of lines to show */
}

.brainButtons{
    transition: all ease-in 150ms;
}

.swipeBlogs {
    width: 90%;
    height: fit-content;
}

.swipeBlogs.mobile{
    display: none;
}
  
.mySwiper.desktop{
    display: block;
    height: 80vh;
}

.swipeSlideBlogs {
    border-radius: 1rem;
  }

  .blog-image-container {
    position: relative;
  }

  .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    color: #fff;
    padding: 2rem;
    box-sizing: border-box;
  }

  .mobile .overlay{
    padding: 1rem;
  }

  .pink-overlay{
    background: linear-gradient(to bottom, rgba(228, 15, 127, 0) 0%, rgba(228, 15, 127, 0.7) 100%);
  }

  /* Add your own styles for the title and description */
  .overlay h2 {
    margin: 0;
  }

  .overlay h3 {
    margin: 0.5rem 0;
    font-size: 1.5rem;
    width: 70%;
  }

  .overlay p {
    margin: 0.5rem 0;
    font-size: 1.2rem;
    width: 70%;
  }

  .custom-navigation{
    --swiper-navigation-color: #0e0e0e;
    --swiper-navigation-size: 50px;
  }

/*******************
    Title section
*******************/

.title-div{
    position: relative;
    padding: 0.3rem 6rem;
    background-color: #e40f7f;
    margin-top: 10rem;
    border-radius: 5rem;
}

.title-div h1{
    font-size: 2.7rem;
    font-weight: 700;
    color: white;
    text-transform: uppercase;
}

.circle-1, .circle-2{
    height: 100%;
    width: 4.5rem;
    position: absolute;
    background-color: #ec619b;
    border-radius: 5rem;
}

.circle-1{
    top:0;
    left: 0;
}

.circle-2{
    top:0;
    right: 0;
}

.brain-title-box{
    flex: 1;
    border-radius: 2rem;
}

.brain-title-box svg{
    height: 72%;
    width: 72%;
}

.ecology svg{
    height: 76%;
    width: 76%;
}

.brain-title-box p {
    text-align: center;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 1.7rem;
    margin: 0.5rem;
}

.brain-lis-box-icon-container{
    width: 100%;
}

/*******************
    Inclusion section
*******************/

.inclusion-div{
    background-color: #6090b0;
    /* height: 10vh; */
    width: 70%;
    position: absolute;
    bottom: -5rem;
    left: 4rem;
}

.brain-news-container img{
    width: 100%;
}

.inclusion-title-div h2{

}

.inclusion-div hr{
    border: 1.5px white solid;
    width: 70%;
}

.inclusion-desc-div{
    margin-top: 3rem;
}

.inclusion-desc-div p{
    font-size: 1.2rem;
}

.inclusion-desc-title{
    font-weight: 700;
}

.inclusion-div .view-more-container {
    position: absolute;
    bottom: 0.7rem;
    right: 1rem;
    padding: 0.2rem 0.7rem;
    border-radius: 1rem;
    font-weight: 500;
    transition: all 200ms ease-in;
  }
  
  .inclusion-div .view-more-container a{
    font-size: 1.2rem;
  }
  
  .inclusion-div .view-more-container:hover{
    scale: 1.15;
  }

  .brain-news-container .video{
    flex:1;
    height: 20rem;
    width: 100%;
  }

  .brain-news-container .slideNextBtn{
    position: absolute;
    top: 45%;
    right: 5%;
    color: #888;
  }

  .brain-news-container .slidePrevBtn{
    position: absolute;
    top: 45%;
    left: 5%;
    color: #888;

  }

  @media (max-width: 1288px) {
    .inclusion-div{
        width: 90%;
        margin: auto;
        display: flex;
        flex-direction: column;
        position: unset;
    }

    .inclusion-container{
        margin-top: -3rem;
        z-index: 99999;
    }
    
    .inclusion-title-div h2{
        font-size: 1.3rem;
        font-weight: 600;
        width: 95%;
    }
    
    .inclusion-div hr{
        border: 1px white solid;
        width: 70%;
    }
    
    .inclusion-desc-div{
        margin-top: 1rem;
    }
    
    .inclusion-desc-div p{
        font-size: 1.1rem;
    }
    
    .inclusion-desc-title{
        font-weight: 700;
    }
    
    .inclusion-div .view-more-container {
        position: unset;
        width: fit-content;
        align-self: center;
        justify-self: center;
        padding: 0.2rem 0.7rem;
        border-radius: 1rem;
        font-weight: 500;
        transition: all 200ms ease-in;
      }
      
      .inclusion-div .view-more-container a{
        font-size: 1rem;
      }
      
      .inclusion-div .view-more-container:hover{
        scale: 1.15;
      }

      .inclusion-div div{
        margin-left: 0;
        padding: 0.5rem
      }

      .listenImage{
        display: none;
      }


      .title-div{
        position: relative;
        padding: 0.3rem 2.7rem;
        background-color: #e40f7f;
        margin-top: 1rem;
        border-radius: 5rem;
    }
    
    .title-div h1{
        font-size: 1.5rem;
        font-weight: 700;
        color: white;
        text-transform: uppercase;
    }
    
    .circle-1, .circle-2{
        height: 100%;
        width: 2.4rem;
        position: absolute;
        background-color: #ec619b;
        border-radius: 5rem;
    }
    
    .circle-1{
        top:0;
        left: 0;
    }
    
    .circle-2{
        top:0;
        right: 0;
    }
    .brain-title-list-container{
        flex-direction: column;
        padding: 2rem;
        gap: 1rem;
    }
    
    .brain-title-box{
        flex: 1;
        display: flex;
        flex-direction: row;
        border-radius: 2rem;
        padding: 0.5rem;
    }
    
    .brain-title-box svg{
        height: 100%;
        width: 100%;
    }
    
    .brain-title-box p {
        text-align: left;
        color: white;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 1.3rem;
        margin: 1rem;
        flex: 4;
    }
    
    .brain-lis-box-icon-container{
        width: 100%;
        height: 5rem;
        flex: 1;
    }

    .brain-vids-container{
        padding: 1rem;
    }

    .brain-news-container .slideNextBtn{
        position: unset;
        top: 45%;
        right: 5%;
        color: #888;
        z-index: 9999;
      }
    
      .brain-news-container .slidePrevBtn{
        position: unset;
        bottom: 0%;
        left: 5%;
        color: #888;
        z-index: 9999;
      }

      .slideNextBtn svg, .slidePrevBtn svg{
        height: 2rem;
      }

  }

  @media (max-width: 600px) {
    .inclusion-div{
        width: 90%;
        margin: auto;
        display: flex;
        flex-direction: column;
        position: unset;
    }

    .button-container{
        bottom: 0;
    }

    .spotify-icon svg, .youtube-icon svg {
        font-size: 25px; /* This sets the icon size */
      }

    .swipeBlogs.mobile{
        display: block;
    }

    .mySwiper.desktop{
        display: none;
    }

    .inclusion-container{
        margin-top: -3rem;
        z-index: 99999;
    }
    
    .inclusion-title-div h2{
        font-size: 1.3rem;
        font-weight: 600;
        width: 95%;
    }
    
    .inclusion-div hr{
        border: 1px white solid;
        width: 70%;
    }
    
    .inclusion-desc-div{
        margin-top: 1rem;
    }
    
    .inclusion-desc-div p{
        font-size: 1.1rem;
    }
    
    .inclusion-desc-title{
        font-weight: 700;
    }
    
    .inclusion-div .view-more-container {
        position: unset;
        width: fit-content;
        align-self: center;
        justify-self: center;
        padding: 0.2rem 0.7rem;
        border-radius: 1rem;
        font-weight: 500;
        transition: all 200ms ease-in;
      }
      
      .inclusion-div .view-more-container a{
        font-size: 1rem;
      }
      
      .inclusion-div .view-more-container:hover{
        scale: 1.15;
      }

      .inclusion-div div{
        margin-left: 0;
        padding: 0.5rem
      }

      .listenImage{
        display: none;
      }


      .title-div{
        position: relative;
        padding: 0.3rem 2.7rem;
        background-color: #e40f7f;
        margin-top: 1rem;
        border-radius: 5rem;
    }
    
    .title-div h1{
        font-size: 1.5rem;
        font-weight: 700;
        color: white;
        text-transform: uppercase;
    }
    
    .circle-1, .circle-2{
        height: 100%;
        width: 2.4rem;
        position: absolute;
        background-color: #ec619b;
        border-radius: 5rem;
    }
    
    .circle-1{
        top:0;
        left: 0;
    }
    
    .circle-2{
        top:0;
        right: 0;
    }
    .brain-title-list-container{
        flex-direction: column;
        padding: 2rem;
        gap: 1rem;
    }
    
    .brain-title-box{
        flex: 1;
        display: flex;
        flex-direction: row;
        border-radius: 2rem;
        padding: 0.5rem;
    }
    
    .brain-title-box svg{
        height: 100%;
        width: 100%;
    }
    
    .brain-title-box p {
        text-align: left;
        color: white;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 1.3rem;
        margin: 1rem;
        flex: 4;
    }
    
    .brain-lis-box-icon-container{
        width: 100%;
        height: 5rem;
        flex: 1;
    }

    .brain-vids-container{
        padding: 1rem;
    }

    .brain-news-container .slideNextBtn{
        position: unset;
        top: 45%;
        right: 5%;
        color: #888;
        z-index: 9999;
      }
    
      .brain-news-container .slidePrevBtn{
        position: unset;
        bottom: 0%;
        left: 5%;
        color: #888;
        z-index: 9999;
      }

      .slideNextBtn svg, .slidePrevBtn svg{
        height: 2rem;
      }

  }